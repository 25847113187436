/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { md3 } from 'vuetify/blueprints'

// Composables
import { createVuetify } from 'vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: localStorage.getItem('theme') || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'),
    themes: {
      light: {
        colors: {
          primary: '#2388cb',
          background: '#eceff1',
        },
      },
      dark: {
        colors: {
          primary: '#2388cb',
          background: '#121212',
        },
      },
    },
  },
  blueprint: md3,
  defaults: {
    VBtn: {
      style: 'text-transform: none; letter-spacing: normal; padding-top: 1px;',
    },
    VCard: {
      flat: true,
    },
    VCardActions: {
      VBtn: {
        variant: 'tonal',
        class: 'px-4',
      },
    },
  },
})
