import httpClient from '../http-client'
import { HotspotUser } from '@/types/hotspot-user'

const ENDPOINT = '/hotspot_users'

export const hotspotUserRepository = {
  async getHotspotUserById (id: string): Promise<HotspotUser> {
    const response = await httpClient.get<HotspotUser>(`${ENDPOINT}/${id}?radius=true`)
    return response.data
  },

  async updateHotspotUser (id: string, hotspotUser: Partial<HotspotUser>): Promise<HotspotUser> {
    const response = await httpClient.put<HotspotUser>(`${ENDPOINT}/${id}`, hotspotUser)
    return response.data
  },

  async createHotspotUser (hotspotUser: Partial<HotspotUser>): Promise<HotspotUser> {
    const response = await httpClient.post<HotspotUser>(`${ENDPOINT}`, hotspotUser)
    return response.data
  },

  async routerosLoginHotspotUser (ip?: string, siteVpnId?: string): Promise<HotspotUser> {
    const response = await httpClient.post<HotspotUser>(`${ENDPOINT}/routeros/login`, { ip, siteVpnId })
    return response.data
  },

  async routerosLogoutHotspotUser (ip?: string): Promise<HotspotUser> {
    const response = await httpClient.post<HotspotUser>(`${ENDPOINT}/routeros/logout`, { ip })
    return response.data
  },

  async routerosIsActiveHotspotUser (): Promise<boolean> {
    const response = await httpClient.get<boolean>(`${ENDPOINT}/routeros/is_active`)
    return response.data
  },
}
