import { JwtUser } from '@/types/jwt-user'
import axios from 'axios'

interface LoginResponse {
  username: string;
  token: string;
  refresh_token: string;
}

interface Auth {
  login(username: string, password: string): Promise<{ success: boolean; token?: string; refreshToken?: string; error?: string }>
  refreshToken(): Promise<{ success: boolean; token?: string; error?: string }>
  logout(): void
  isLoggedIn(): boolean
  isTokenExpired(): boolean
  getToken(): string | null
  getRefreshToken(): string | null
  getUser(): JwtUser | null
}

const httpClient = axios.create({
  baseURL: import.meta.env.VITE_SYNTELIX_CORE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

const auth: Auth = {
  async login (username: string, password: string) {
    try {
      const response = await httpClient.post<LoginResponse>('/hotspot_login_check', { username, password })
      const { token, refresh_token: refreshToken } = response.data
      localStorage.setItem('stx_auth', JSON.stringify({ token, refreshToken }))
      return { success: true, token, refreshToken }
    } catch (error: any) {
      return { success: false, error: error.response?.data?.message || 'Login failed' }
    }
  },
  async refreshToken () {
    const storedRefreshToken = this.getRefreshToken()
    if (!storedRefreshToken) {
      return { success: false, error: 'No refresh token stored' }
    }
    try {
      const response = await httpClient.post<LoginResponse>('/hotspot_login_refresh', {
        refresh_token: storedRefreshToken,
      })
      const { token, refresh_token: refreshToken } = response.data
      localStorage.setItem('stx_auth', JSON.stringify({ token, refreshToken }))
      return { success: true, token, refreshToken }
    } catch (error: any) {
      return { success: false, error: error.response?.data?.message || 'Token refresh failed' }
    }
  },
  logout () {
    localStorage.removeItem('stx_auth')
  },
  isLoggedIn () {
    return !!this.getToken()
  },
  isTokenExpired (): boolean {
    const user = this.getUser()
    if (!user) return true
    return Date.now() >= user.exp * 1000
  },
  getToken () {
    const stxAuth = localStorage.getItem('stx_auth')
    if (!stxAuth) return null
    return JSON.parse(stxAuth).token
  },
  getRefreshToken () {
    const stxAuth = localStorage.getItem('stx_auth')
    if (!stxAuth) return null
    return JSON.parse(stxAuth).refreshToken
  },
  getUser (): JwtUser | null {
    const token = this.getToken()
    if (!token) return null
    const [, payload] = token.split('.')
    return JSON.parse(atob(payload))
  },
}

export default auth
