import { useHotspotStore } from '@/stores/hotspot'
import { hotspotUserRepository } from '@/syntelix-core-api/repositories/hotspot-user-repository'
import { HotspotUser } from '@/types/hotspot-user'
import { ApiError } from '@/types/api-error'
import { defineStore } from 'pinia'

export const useHotspotUserStore = defineStore('hotspotUser', {
  state: () => ({
    hotspotUser: null as HotspotUser | null,
    loading: false,
    error: null as ApiError | null,
  }),

  actions: {
    async fetchHotspotUser (id: string) {
      this.loading = true
      try {
        this.hotspotUser = await hotspotUserRepository.getHotspotUserById(id)
      } catch (error) {
        this.error = error as ApiError
      }
      this.loading = false
    },
    async updateHotspotUser (id: string, hotspotUser: Partial<HotspotUser>) {
      this.loading = true
      try {
        this.hotspotUser = await hotspotUserRepository.updateHotspotUser(id, hotspotUser)
      } catch (error) {
        this.error = error as ApiError
      }
      this.loading = false
    },

    async routerosLogin (ip?: string, siteVpnId?: string) {
      this.loading = true
      try {
        await hotspotUserRepository.routerosLoginHotspotUser(ip, siteVpnId)
      } catch (error) {
        this.error = error as ApiError
      }
      this.loading = false
      await useHotspotStore().fetchStatus()
    },
    async routerosLogout (ip?: string) {
      this.loading = true
      try {
        await hotspotUserRepository.routerosLogoutHotspotUser(ip)
      } catch (error) {
        this.error = error as ApiError
      }
      this.loading = false
      await useHotspotStore().fetchStatus()
    },
    addCredit (credit: number) {
      if (this.hotspotUser) {
        this.hotspotUser.credit += credit
      }
    },
    hasNoCredit () {
      if (this.hotspotUser) {
        return this.hotspotUser.credit <= 0
      }
    },
  },
})
