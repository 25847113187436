import { createI18n, I18nOptions } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'

const numberFormats: I18nOptions['numberFormats'] = {
  us: {
    currency: {
      style: 'currency', currency: 'US', notation: 'standard',
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent', useGrouping: false,
    },
  },
  es: {
    currency: {
      style: 'currency', currency: 'EUR', notation: 'standard',
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent', useGrouping: false,
    },
  },
  USD: {
    currency: {
      style: 'currency', currency: 'USD', notation: 'standard',
    },
  },
  EUR: {
    currency: {
      style: 'currency', currency: 'EUR', notation: 'standard',
    },
  },
}

const datetimeFormats: I18nOptions['datetimeFormats'] = {
  en: {
    short: {
      year: 'numeric', month: 'short', day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
    hour: {
      hour: 'numeric',
      minute: 'numeric',
    },
    chartDate: {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  es: {
    short: {
      year: 'numeric', month: 'short', day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
    hour: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
    chartDate: {
      month: 'short',
      day: 'numeric',
    },
  },
}

export default createI18n({
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'en',
  messages,
  numberFormats,
  datetimeFormats,
})
