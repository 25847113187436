import * as Sentry from '@sentry/vue'
import type { App } from 'vue'
import router from '@/router'

export function initSentry (app: App) {
  if (import.meta.env.MODE === 'development') {
    return
  }
  Sentry.init({
    app,
    dsn: 'https://0278b310c3d1772287d320cfe9e6d117@sentry.syntelix.net/3',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https?:\/\/([a-zA-Z0-9-]+\.)*usail\.es/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.MODE,
  })
}
