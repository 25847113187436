import auth from '@/syntelix-core-api/auth'
import { ApiError, ApiViolations } from '@/types/api-error'
import { ApiViolation } from '@/types/api-violation'
import { HydraError } from '@/types/hydra/hydra-error'
import axios from 'axios'

const httpClient = axios.create({
  baseURL: import.meta.env.VITE_SYNTELIX_CORE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

// Request interceptor to add the token to the request headers
httpClient.interceptors.request.use(
  async config => {
    if (config.data?._stx_auth === false) {
      return config
    }

    if (auth.isTokenExpired()) {
      await auth.refreshToken()
    }

    const token = auth.getToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// Response interceptor to handle errors

httpClient.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (!error.response?.data['@context']) {
      return Promise.reject(error)
    }

    const hydraError: HydraError = error.response.data as HydraError

    // convert Hydra error to ApiError
    const violations: ApiViolations = {}
    if (hydraError.violations) {
      hydraError.violations.forEach((violation: ApiViolation) => {
        violations[violation.propertyPath] = violation.message
      })
    }

    const apiError: ApiError = {
      title: hydraError['hydra:title'],
      description: hydraError['hydra:description'],
      violations,
    }

    return Promise.reject(apiError)
  }
)

export default httpClient
