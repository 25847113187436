export default function subscribeToEvent (
  topic: string,
  eventToHandle: string,
  eventHandler: (data: any, event: string) => void
) {
  const url = new URL(import.meta.env.VITE_SYNTELIX_CORE_API_URL + '/.well-known/mercure')
  url.searchParams.append('topic', topic)

  const eventSource = new EventSource(url)

  eventSource.onmessage = ev => {
    const { data, event } = JSON.parse(ev.data)
    if (event === eventToHandle) {
      eventHandler(data, event)
    }
  }

  return eventSource
}
