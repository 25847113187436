import axios from 'axios'
import { defineStore } from 'pinia'
import { HotspotStatus } from '@/types/hotspot-status'

export const useHotspotStore = defineStore('hotspot', {
  state: () => ({
    status: null as HotspotStatus | null,
    loading: false,
    error: null as Error | null,
  }),

  actions: {
    async fetchStatus () {
      this.loading = true
      try {
        const response = await axios.get('https://internet.usail.es/status.json.html')
        this.status = response.data as HotspotStatus
      } catch (error: any) {
        this.error = error
      }
      this.loading = false
    },
  },
})
