<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script lang="ts" setup>
  import { useHotspotStore } from '@/stores/hotspot'
  import { useHotspotUserStore } from '@/stores/hotspot-user'
  import subscribeToEvent from '@/syntelix-core-api/mercure'
  import { HotspotUser } from '@/types/hotspot-user'
  import { useTheme } from 'vuetify'

  const theme = useTheme()

  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
    theme.global.name.value = event.matches ? 'dark' : 'light'
  })

  const hotspotStore = useHotspotStore()
  hotspotStore.fetchStatus()

  const hotspotUserStore = useHotspotUserStore()

  watch(
    () => hotspotUserStore.hotspotUser,
    (hotspotUser: HotspotUser | null) => {
      if (hotspotUser) {
        subscribeToEvent(hotspotUser['@id'], 'hotspot-user-logged-in', () => {
          hotspotStore.fetchStatus()
        })
        subscribeToEvent(hotspotUser['@id'], 'hotspot-user-logged-out', () => {
          hotspotStore.fetchStatus()
        })
        subscribeToEvent(hotspotUser['@id'], 'hotspot-credit-added', data => {
          hotspotUserStore.addCredit(data.creditAmount)
        })
      }
    },
    { immediate: true }
  )
</script>
